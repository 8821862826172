
:root{
    --color-primary: #655cf5;
    --color-variant-blue: #5e53f3;
    --color-secondary: #C2BFFE;
    --color-text-blue: #444A6D;
    --colot-text-title: #05004E;
    --color-black: #111111;
    --color-border: #0003;
    --color-white: #ffffff;
    --color-white-variante: rgba(255,255,255, 85%);
    --color-neutral: #8F8888;
    --color-gray: #f0f2f5;
    --color-grayBlue: #e4e6ff;
    --color-red: #fb8085;
    --color-yellow: #fbc764;
    --color-green: #38da23;
    --color-lightGray: #e0e0e0;
    --color-darkGray: #333;
    --color-primaryDark: #2980b9; 
    --color-danger: #e74c3c; 
    --color-dangerDark: #c0392b;
}